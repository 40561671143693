import React from 'react'
import { Grid } from '@mui/material'
import Component from './component'
const Container = ({ data }) => {
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', gap: .5, alignItems: 'center' }}>
      {_.map(data, (event) => <Component key={event?.key} event={event} />)}
    </Grid>
  )
}

export default Container
